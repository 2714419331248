import React from "react";
import "../../App";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import LeaderBoardAd from "../../Components/LeaderBoardAd";

export default function Privacy() {
  return (
    <>
      <Navbar />
      <h1 className="text-center mt-4">Privacy Policy</h1>
      <div className="container body-wrapper my-4">
        <div className="card p-4">
          <h5 className="text-left">Shrestha Apps</h5>

          <section className="mt-4">
            <h5>Privacy Policy</h5>
            <p>
              We are committed to protecting your privacy. This Privacy Policy
              explains how we use the information that we collect when you use
              our mobile app.
            </p>
          </section>

          <section className="mt-4">
            <h6>
              <b>Information We Collect</b>
            </h6>
            <p>
              We do not collect any personal information or usage data when you
              use the app.
            </p>
          </section>

          <section className="mt-4">
            <h6>
              <b>Data Retention</b>
            </h6>
            <p>
              We do not retain any information through the app. Data are saved
              locally on your device and are only accessible within your device.
            </p>
          </section>

          <section className="mt-4">
            <h5>Advertisement Policy</h5>
            <p>
              In order to provide a better user experience and support the
              development of our app, we may display advertisements within the
              app. This policy explains how and when ads may be shown.
            </p>
          </section>

          <section className="mt-3">
            <h6>
              <b>Types of Ads</b>
            </h6>
            <p>
              Our mobile app may display banner ads, interstitial ads, and video
              ads. These ads may appear during app usage but will not disrupt
              functionality.
            </p>
          </section>

          <section className="mt-3">
            <h6>
              <b>Advertising Partners</b>
            </h6>
            <p>
              Our app may work with third-party advertising partners to display
              ads. These partners may collect data for ad personalization.
              <br />
              <a
                href="https://support.google.com/admob/answer/6128543?hl=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here for AdMob Policy.
              </a>
            </p>
          </section>

          <section className="mt-4">
            <h5>Changes to This Privacy Policy</h5>
            <p>
              We may update this Privacy Policy occasionally to reflect changes
              in practices or for legal reasons. We encourage reviewing it
              regularly to stay informed.
            </p>
          </section>

          <section className="mt-4">
            <h5>Contact Us</h5>
            <p>
              If you have any questions about this Privacy Policy or our
              practices, please contact me via{" "}
              <a
                href="https://www.linkedin.com/in/shrestha-manoj/"
                target="_blank"
                rel="noopener noreferrer"
              >
                LinkedIn
              </a>
              .
            </p>
          </section>

          <div className="text-muted mt-4">
            <small>
              <b>Last updated: April 12, 2025</b>
            </small>
          </div>
        </div>
        <LeaderBoardAd />
      </div>
      <Footer />
    </>
  );
}
